import { Component, input } from '@angular/core';
import { FfNgxIconComponent } from '@fagforbundet/ngx-components';
import { Api } from '@core/models/oidc/api/api';

@Component({
  standalone: true,
  selector: 'app-api-list-api-entry',
  templateUrl: './api-list-api-entry.component.html',
  styleUrls: ['./api-list-api-entry.component.scss'],
  imports: [
    FfNgxIconComponent,
  ],
  host: {
    class: 'api ff-card',
  },
})
export class ApiListApiEntryComponent {
  api = input.required<Api>();
}
