import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { ClientListComponent } from '@shared/components/client-list/client-list.component';
import { DeveloperListComponent } from '@shared/components/developer-list/developer-list.component';
import { FeedbackMessagesComponent } from '@shared/components/feedback-messages/feedback-messages.component';
import {
  FfAcceptCancelInputComponent,
} from '@shared/components/ff-accept-cancel-input/ff-accept-cancel-input.component';
import {
  FfAssignCollectionItemComponent,
} from '@shared/components/ff-assign-collection-item/ff-assign-collection-item.component';
import { FfButtonComponent } from '@shared/components/ff-button/ff-button.component';
import { FfCheckboxComponent } from '@shared/components/ff-checkbox/ff-checkbox.component';
import { FfDeleteButtonComponent } from '@shared/components/ff-delete-button/ff-delete-button.component';
import { FfDropdownComponent } from '@shared/components/ff-dropdown/ff-dropdown.component';
import { FfErrorPageComponent } from '@shared/components/ff-error-page/ff-error-page.component';
import { FfHeaderComponent } from '@shared/components/ff-header/ff-header.component';
import { FfIconInputComponent } from '@shared/components/ff-icon-input/ff-icon-input.component';
import { FfListItemComponent } from '@shared/components/ff-list-item/ff-list-item.component';
import { FfLoadingIndicatorComponent } from '@shared/components/ff-loading-indicator/ff-loading-indicator.component';
import { FfLoadingScreenComponent } from '@shared/components/ff-loading-screen/ff-loading-screen.component';
import { FfMenuButtonComponent } from '@shared/components/ff-menu-button/ff-menu-button.component';
import { FfMenuItemComponent } from '@shared/components/ff-menu-item/ff-menu-item.component';
import { FfConfirmModalComponent } from '@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal.component';
import { FfModalBaseClassDirective } from '@shared/components/ff-modals/ff-modal-base.class';
import { FfModalHostComponent } from '@shared/components/ff-modals/ff-modal-host/ff-modal-host.component';
import { FfTextModalComponent } from '@shared/components/ff-modals/ff-text-modal/ff-text-modal.component';
import { FfPaginationComponent } from '@shared/components/ff-pagination/ff-pagination.component';
import { FfRadioComponent } from '@shared/components/ff-radio/ff-radio.component';
import {
  FfToggleRevealInputComponent,
} from '@shared/components/ff-toggle-reveal-input/ff-toggle-reveal-input.component';
import { FfTooltipComponent } from '@shared/components/ff-tooltip/ff-tooltip.component';
import { FfTypeaheadComponent } from '@shared/components/ff-typeahead/ff-typeahead.component';
import { FfWellComponent } from '@shared/components/ff-well/ff-well.component';
import { InformAndProceedComponent } from '@shared/components/inform-and-proceed/inform-and-proceed.component';
import { MainMenuComponent } from '@shared/components/main-menu/main-menu.component';
import { OauthConsentComponent } from '@shared/components/oauth-consent/oauth-consent.component';
import { PublicHeaderComponent } from '@shared/components/public-header/public-header.component';
import {
  ReactiveCheckboxTreeViewComponent,
} from '@shared/components/reactive-checkbox-tree-view/reactive-checkbox-tree-view.component';
import { SetUserPasswordComponent } from '@shared/components/set-user-password/set-user-password.component';
import { SvgComponent } from '@shared/components/svg/svg.component';
import { TestUserListComponent } from '@shared/components/test-user-list/test-user-list.component';
import {
  UserAccountStatusInfoComponent,
} from '@shared/components/user-account-status-info/user-account-status-info.component';
import { AddressFormComponent } from '@shared/components/user-form/address-form/address-form.component';
import { EmailFormComponent } from '@shared/components/user-form/emails/email-form/email-form.component';
import { EmailsComponent } from '@shared/components/user-form/emails/emails.component';
import { ExistingEmailComponent } from '@shared/components/user-form/emails/existing-email/existing-email.component';
import { ExistingPhoneComponent } from '@shared/components/user-form/phones/existing-phone/existing-phone.component';
import { PhoneFormComponent } from '@shared/components/user-form/phones/phone-form/phone-form.component';
import { PhonesComponent } from '@shared/components/user-form/phones/phones.component';
import { UserFormComponent } from '@shared/components/user-form/user-form.component';
import {
  UserMemberIdFormComponent,
} from '@shared/components/user-form/user-member-id-form/user-member-id-form.component';
import {
  UserPermissionFormComponent,
} from '@shared/components/user-form/user-permission-form/user-permission-form.component';
import { UserSsnFormComponent } from '@shared/components/user-form/user-ssn-form/user-ssn-form.component';
import { UserTypeFormComponent } from '@shared/components/user-form/user-type-form/user-type-form.component';
import { UserPasswordFormComponent } from '@shared/components/user-password-form/user-password-form.component';
import { UserSessionInfoComponent } from '@shared/components/user-session-info/user-session-info.component';
import {
  UserVerificationInfoComponent,
} from '@shared/components/user-verification-info/user-verification-info.component';
import { PostalLookupDirective } from '@shared/directives/postal-lookup.directive';
import { BankAccountPipe } from '@shared/pipes/bank-account.pipe';
import { LoginErrorPipe } from '@shared/pipes/login-error/login-error.pipe';
import { ObjToKeysPipe } from '@shared/pipes/obj-to-keys.pipe';
import { PhonePipe } from '@shared/pipes/phone.pipe';
import { SafePipe } from '@shared/pipes/safe.pipe';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxPaginationModule } from 'ngx-pagination';
import { GlobalSearchComponent } from '@shared/components/global-search/global-search.component';
import {
  UserNameAndAddressComponent,
} from './components/user-form/user-name-and-address/user-name-and-address.component';
import { UserNameFormComponent } from './components/user-form/user-name-form/user-name-form.component';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import {
  FfNgxAutocompleteComponent,
  FfNgxAutocompleteTriggerDirective,
  FfNgxButtonComponent,
  FfNgxFormFieldComponent,
  FfNgxFormFieldErrorComponent,
  FfNgxIconComponent,
  FfNgxInputContainerComponent,
  FfNgxInputContainerSuffixDirective,
  FfNgxInputDirective,
  FfNgxLoadingIndicatorComponent,
  FfNgxOptionComponent,
} from '@fagforbundet/ngx-components';
import { ParentLinkComponent } from '@shared/components/parent-link/parent-link.component';
import {
  CorsUrlListCorsUrlEntryComponent,
} from '@shared/components/cors-url-list-cors-url-entry/cors-url-list-cors-url-entry.component';
import { ApiListApiEntryComponent } from '@shared/components/api-list-api-entry/api-list-api-entry.component';
import {
  ClientListClientEntryComponent,
} from '@shared/components/client-list-client-entry/client-list-client-entry.component';

@NgModule({
  imports: [
    A11yModule,
    AngularSvgIconModule,
    CommonModule,
    CoreModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    RouterModule,
    ParentLinkComponent,
    FfNgxButtonComponent,
    FfNgxIconComponent,
    FfNgxLoadingIndicatorComponent,
    CorsUrlListCorsUrlEntryComponent,
    FfNgxFormFieldComponent,
    FfNgxFormFieldErrorComponent,
    FfNgxInputContainerComponent,
    FfNgxInputDirective,
    FfNgxAutocompleteComponent,
    FfNgxAutocompleteTriggerDirective,
    FfNgxInputContainerSuffixDirective,
    FfNgxOptionComponent,
    ApiListApiEntryComponent,
    ClientListClientEntryComponent,
  ],
  exports: [
    ClientListComponent,
    CommonModule,
    DeveloperListComponent,
    FeedbackMessagesComponent,
    FfAcceptCancelInputComponent,
    FfAssignCollectionItemComponent,
    FfButtonComponent,
    FfCheckboxComponent,
    FfDeleteButtonComponent,
    FfDropdownComponent,
    FfErrorPageComponent,
    FfHeaderComponent,
    FfIconInputComponent,
    FfListItemComponent,
    FfLoadingIndicatorComponent,
    FfLoadingScreenComponent,
    FfMenuButtonComponent,
    FfMenuItemComponent,
    FfModalHostComponent,
    FfPaginationComponent,
    FfRadioComponent,
    FfToggleRevealInputComponent,
    FfTooltipComponent,
    FfTypeaheadComponent,
    FfWellComponent,
    GlobalSearchComponent,
    InformAndProceedComponent,
    LoginErrorPipe,
    MainMenuComponent,
    NgxPaginationModule,
    OauthConsentComponent,
    PublicHeaderComponent,
    ReactiveFormsModule,
    SetUserPasswordComponent,
    SvgComponent,
    TestUserListComponent,
    UserAccountStatusInfoComponent,
    UserFormComponent,
    UserMemberIdFormComponent,
    UserPasswordFormComponent,
    UserPermissionFormComponent,
    UserSessionInfoComponent,
    UserSsnFormComponent,
    UserTypeFormComponent,
    UserVerificationInfoComponent,
    PhonePipe,
    BankAccountPipe,
    PhoneFormComponent,
  ],
  declarations: [
    AddressFormComponent,
    BankAccountPipe,
    ClientListComponent,
    DeveloperListComponent,
    EmailFormComponent,
    EmailsComponent,
    ExistingEmailComponent,
    ExistingPhoneComponent,
    FeedbackMessagesComponent,
    FfAcceptCancelInputComponent,
    FfAssignCollectionItemComponent,
    FfButtonComponent,
    FfCheckboxComponent,
    FfConfirmModalComponent,
    FfDeleteButtonComponent,
    FfDropdownComponent,
    FfErrorPageComponent,
    FfHeaderComponent,
    FfIconInputComponent,
    FfListItemComponent,
    FfLoadingIndicatorComponent,
    FfLoadingScreenComponent,
    FfMenuButtonComponent,
    FfMenuItemComponent,
    FfModalBaseClassDirective,
    FfModalHostComponent,
    FfPaginationComponent,
    FfRadioComponent,
    FfTextModalComponent,
    FfToggleRevealInputComponent,
    FfTooltipComponent,
    FfTypeaheadComponent,
    FfWellComponent,
    GlobalSearchComponent,
    InformAndProceedComponent,
    LoginErrorPipe,
    MainMenuComponent,
    OauthConsentComponent,
    ObjToKeysPipe,
    PhoneFormComponent,
    PhonePipe,
    PhonesComponent,
    PostalLookupDirective,
    PublicHeaderComponent,
    ReactiveCheckboxTreeViewComponent,
    SafePipe,
    SetUserPasswordComponent,
    SvgComponent,
    TestUserListComponent,
    UserAccountStatusInfoComponent,
    UserFormComponent,
    UserNameFormComponent,
    UserMemberIdFormComponent,
    UserPasswordFormComponent,
    UserPermissionFormComponent,
    UserSessionInfoComponent,
    UserSsnFormComponent,
    UserTypeFormComponent,
    UserVerificationInfoComponent,
    UserNameAndAddressComponent,
    Nl2brPipe,
  ],
  providers: [],
})
export class SharedModule {
}
