import {
  DeleteRemoveApiDeveloperResponseDto,
} from '@core/dtos/id-api/apis/developers/delete-remove-api-developer.response-dto';
import { Api } from '@core/models/oidc/api/api';
import { GrantAbbreviations } from '@core/enums/grant-abbreviations';
import { GrantIcons } from '@core/enums/grant-icons';

export function deleteRemoveApiDeveloperResponseMapper(
  deleteRemoveApiDeveloperResponseDto: DeleteRemoveApiDeveloperResponseDto
): Api {
  return {
    uuid: deleteRemoveApiDeveloperResponseDto.api.uuid,
    createdAt: new Date(deleteRemoveApiDeveloperResponseDto.api.createdAt),
    name: deleteRemoveApiDeveloperResponseDto.api.name,
    description: deleteRemoveApiDeveloperResponseDto.api.description,
    namespace: deleteRemoveApiDeveloperResponseDto.api.namespace,
    clients: deleteRemoveApiDeveloperResponseDto.api.clients.map((c) => {
      return {
        uuid: c.uuid,
        createdAt: new Date(c.createdAt),
        name: c.name,
        description: c.description,
        type: c.type,
        active: c.active,
        bypassAuthorization: c.bypassAuthorization,
        canOmitScopes: c.canOmitScopes,
        devMode: c.devMode,
        public: c.public,
        requireEmailAddress: c.requireEmailAddress,
        requireFirstNameAndLastName: c.requireFirstNameAndLastName,
        requirePhoneNumber: c.requirePhoneNumber,
        requirePostalAddress: c.requirePostalAddress,
        requireInformationUpdate: c.requireInformationUpdate,
        allowedGrants: c.allowedGrants?.map((ag) => {
          return {
            uuid: ag.uuid,
            name: ag.name,
            abbreviation: GrantAbbreviations[ag.name],
            icon: GrantIcons[ag.name],
          };
        }),
      };
    }),
    developers: deleteRemoveApiDeveloperResponseDto.api.developers.map((d) => {
      return {
        uuid: d.uuid,
        firstName: d.firstName,
        lastName: d.lastName,
        primaryPhoneNumber: d.phone,
        primaryEmailAddress: d.email,
      };
    }),
    revokeUrls: deleteRemoveApiDeveloperResponseDto.api.revokeUrls.map((ru) => {
      return {
        uuid: ru.uuid,
        createdAt: new Date(ru.createdAt),
        url: ru.url,
      };
    }),
    scopes: deleteRemoveApiDeveloperResponseDto.api.scopes.map((s) => {
      return {
        uuid: s.uuid,
        createdAt: new Date(s.createdAt),
        name: s.name,
        description: s.description,
        global: s.global,
        inUse: s.inUse,
      };
    }),
  };
}
