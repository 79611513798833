import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CLIENT_ENTITY_SCHEMA } from '@core/entities/api/api-client-entity.schema';
import { ClientEntity } from '@core/entities/client/client.entity';
import { ClientTypesEnum } from '@core/enums/client-types.enum';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize } from 'serializr';
import { RequestService } from '@core/services/request.service';
import { IdApiEndpointsEnum } from '@core/enums/api-endpoints/id-api.enum';
import { ClientType } from '@core/models/oidc/client/client-type';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';
import idApiConfig from '@config/apis/id/id-api.config';
import { PatchOauthClientRequestDto } from '@core/dtos/id-api/clients/oauth/patch-oauth-client-request.dto';
import { PatchOauthClientResponseDto } from '@core/dtos/id-api/clients/oauth/patch-oauth-client.response-dto';
import {
  PostAddClientDeveloperResponseDto,
} from '@core/dtos/id-api/clients/oauth/developers/post-add-client-developer.response-dto';
import {
  PostAddClientTestUserResponseDto,
} from '@core/dtos/id-api/clients/oauth/test-users/post-add-client-test-user.response-dto';
import { GetClientsResponseDto } from '@core/dtos/id-api/clients/get-clients.response-dto';
import { PatchClientResponseDto } from '@core/dtos/id-api/clients/patch-client.response-dto';
import {
  PostAddClientLoginProviderResponseDto,
} from '@core/dtos/id-api/clients/oauth/login-providers/post-add-client-login-provider.response-dto';
import {
  DeleteRemoveClientLoginProviderResponseDto,
} from '@core/dtos/id-api/clients/oauth/login-providers/delete-remove-client-login-provider.response-dto';
import { GetOauthClientResponseDto } from '@core/dtos/id-api/clients/oauth/get-oauth-client.response-dto';
import { GetSsoClientResponseDto } from '@core/dtos/id-api/clients/sso/get-sso-client.response-dto';
import {
  GetOAuthClientScopesResponseDto,
} from '@core/dtos/id-api/clients/oauth/scopes/get-o-auth-client-scopes-response.dto';
import { GetClientApisResponseDto } from '@core/dtos/id-api/clients/oauth/apis/get-client-apis.response-dto';
import {
  GetClientRedirectUrisResponseDto,
} from '@core/dtos/id-api/clients/redirect-uris/get-client-redirect-uris.response-dto';
import {
  GetOauthClientPostLogoutRedirectUrisResponseDto,
} from '@core/dtos/id-api/clients/oauth/post-logout-redirect-uris/get-oauth-client-post-logout-redirect-uris.response-dto';
import {
  GetOauthClientCorsUrlsResponseDto,
} from '@core/dtos/id-api/clients/oauth/cors-urls/get-oauth-client-cors-urls-response.dto';
import {
  GetClientTestUsersResponseDto,
} from '@core/dtos/id-api/clients/oauth/test-users/get-client-test-users.response-dto';
import {
  GetClientDevelopersResponseDto,
} from '@core/dtos/id-api/clients/oauth/developers/get-client-developers.response-dto';
import {
  DeleteRemoveClientDeveloperResponseDto,
} from '@core/dtos/id-api/clients/oauth/developers/delete-remove-client-developer.response-dto';
import {
  DeleteRemoveClientTestUserResponseDto,
} from '@core/dtos/id-api/clients/oauth/test-users/delete-remove-client-test-user.response-dto';
import {
  DeleteRemoveClientScopeResponseDto,
} from '@core/dtos/id-api/clients/oauth/scopes/delete-remove-client-scope.response-dto';
import {
  PostAddClientScopeResponseDto,
} from '@core/dtos/id-api/clients/oauth/scopes/post-add-client-scope.response-dto';
import { PutClientScopeResponseDto } from '@core/dtos/id-api/clients/oauth/scopes/put-client-scope.response-dto';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  readonly #httpClient = inject(HttpClient);
  readonly #requestService = inject(RequestService);

  patchOauthClient(
    clientUuid: string,
    requestDto: PatchOauthClientRequestDto,
  ): Observable<PatchOauthClientResponseDto> {
    return this.#httpClient.patch<PatchOauthClientResponseDto>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.PUT_OAUTH_CLIENT.path,
        {
          uuid: clientUuid,
        },
      ).toString(), {
        attribute: requestDto.attribute,
        value: requestDto.value,
      },
    );
  }

  assignDeveloper(
    clientUuid: string,
    clientType: ClientType,
    developerUuid: string,
  ): Observable<PostAddClientDeveloperResponseDto> {
    const path = clientType === ClientType.OAUTH ?
      idApiConfig.endpoints.POST_ADD_OAUTH_CLIENT_DEVELOPER.path :
      idApiConfig.endpoints.POST_ADD_SSO_CLIENT_DEVELOPER.path;

    return this.#httpClient
      .post<PostAddClientDeveloperResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          path,
          {
            clientUuid,
            developerUuid,
          },
        ).toString(),
        {},
      );
  }

  assignScope(
    clientUuid: string,
    scopeUuid: string,
  ): Observable<PostAddClientScopeResponseDto> {
    return this.#httpClient
      .post<PostAddClientScopeResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_ADD_OAUTH_CLIENT_SCOPE.path,
          {
            clientUuid,
            scopeUuid,
          },
        ).toString(),
        {
          required: false,
        },
      );
  }

  assignTestUser(
    clientUuid: string,
    clientType: ClientType,
    testUserUuid: string,
  ): Observable<PostAddClientTestUserResponseDto> {
    const path = clientType === ClientType.OAUTH ?
      idApiConfig.endpoints.POST_ADD_OAUTH_CLIENT_TEST_USER.path :
      idApiConfig.endpoints.POST_ADD_SSO_CLIENT_TEST_USER.path;

    return this.#httpClient
      .post<PostAddClientTestUserResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          path,
          {
            clientUuid,
            testUserUuid,
          },
        ).toString(),
        {},
      );
  }

  getClients(
    limit: number = 20,
    offset: number = 0,
    query?: string,
    onlyAccessible: boolean = true,
    type?: ClientType,
  ): Observable<GetClientsResponseDto> {
    let params = new HttpParams();
    params = params.set('limit', limit);
    params = params.set('offset', offset);

    if (query) {
      params = params.set('query', query);
    }
    if (type) {
      params = params.set('type', type);
    }

    params = params.set('only-accessible', onlyAccessible.toString());

    return this.#httpClient
      .get<GetClientsResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.GET_CLIENTS.path,
        ).toString(), {
          params,
        });
  }

  getClientInfo(
    clientUuid: string,
    clientType: ClientType = ClientType.OAUTH,
  ): Observable<ClientEntity> {
    const path = clientType === ClientType.OAUTH ?
      idApiConfig.endpoints.GET_OAUTH_CLIENT_INFO.path :
      idApiConfig.endpoints.GET_SSO_CLIENT_INFO.path;

    return this.#httpClient
      .get(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          path,
          {
            clientUuid,
          },
        ).toString(),
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client,
          );
        }),
      );
  }

  getInfoByRedirectUri(
    uri: string,
    clientType: ClientTypesEnum = ClientTypesEnum.OAUTH,
  ): Observable<ClientEntity> {
    return this.#httpClient
      .get(
        EnvironmentHelper.fetchAPIBase(
          'v1/' + clientType + '-clients/info/by-redirect-uri/' + uri,
        ),
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client,
          );
        }),
      );
  }

  patchClient(
    clientUuid: string,
    clientType: ClientType,
    property: string,
    value: any,
  ): Observable<PatchClientResponseDto> {
    return this.#httpClient
      .patch<PatchClientResponseDto>(
        EnvironmentHelper.fetchAPIBase(
          'v1/' + clientType + '-clients/' + clientUuid,
        ),
        {
          attribute: property,
          value,
        },
      );
  }

  addClientLoginProvider(
    clientUuid: string,
    loginProviderUuid: string,
  ): Observable<PostAddClientLoginProviderResponseDto> {
    return this.#httpClient
      .post<PostAddClientLoginProviderResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_ADD_OAUTH_CLIENT_LOGIN_PROVIDER.path,
          {
            clientUuid,
            loginProviderUuid,
          },
        ).toString(),
        {},
      );
  }

  removeClientLoginProvider(
    clientUuid: string,
    loginProviderUuid: string,
  ): Observable<DeleteRemoveClientLoginProviderResponseDto> {
    return this.#httpClient
      .delete<DeleteRemoveClientLoginProviderResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.DELETE_REMOVE_OAUTH_CLIENT_LOGIN_PROVIDER.path,
          {
            clientUuid,
            loginProviderUuid,
          },
        ).toString(),
      );
  }

  toggleScopeRequired(
    clientUuid: string,
    scopeUuid: string,
    scopeRequired: boolean,
  ): Observable<PutClientScopeResponseDto> {
    return this.#httpClient
      .put<PutClientScopeResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.PUT_OAUTH_CLIENT_SCOPE.path,
          {
            clientUuid,
            scopeUuid,
          },
        ).toString(),
        {
          required: scopeRequired,
        },
      );
  }

  unAssignDeveloper(
    clientUuid: string,
    clientType: ClientType,
    developerUuid: string,
  ): Observable<DeleteRemoveClientDeveloperResponseDto> {
    const path = clientType === ClientType.OAUTH ?
      idApiConfig.endpoints.DELETE_REMOVE_OAUTH_CLIENT_DEVELOPER.path :
      idApiConfig.endpoints.DELETE_REMOVE_SSO_CLIENT_DEVELOPER.path;

    return this.#httpClient
      .delete<DeleteRemoveClientDeveloperResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          path,
          {
            clientUuid,
            developerUuid,
          },
        ).toString(),
        {},
      );
  }

  unAssignScope(
    clientUuid: string,
    scopeUuid: string,
  ): Observable<DeleteRemoveClientScopeResponseDto> {
    return this.#httpClient
      .delete<DeleteRemoveClientScopeResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.DELETE_REMOVE_OAUTH_CLIENT_SCOPE.path,
          {
            clientUuid,
            scopeUuid,
          },
        ).toString(),
        {},
      );
  }

  unAssignTestUser(
    clientUuid: string,
    clientType: ClientType,
    testUserUuid: string,
  ): Observable<DeleteRemoveClientTestUserResponseDto> {
    const path = clientType === ClientType.OAUTH ?
      idApiConfig.endpoints.DELETE_REMOVE_OAUTH_CLIENT_TEST_USER.path :
      idApiConfig.endpoints.DELETE_REMOVE_SSO_CLIENT_TEST_USER.path;

    return this.#httpClient
      .delete<DeleteRemoveClientTestUserResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          path,
          {
            clientUuid,
            testUserUuid,
          },
        ).toString(),
        {},
      );
  }

  getOauthClient(clientUuid: string): Observable<GetOauthClientResponseDto> {
    return this.#requestService
      .get<GetOauthClientResponseDto>(IdApiEndpointsEnum.GET_OAUTH_CLIENT, {
        clientUuid,
      });
  }

  getSsoClient(clientUuid: string): Observable<GetSsoClientResponseDto> {
    return this.#requestService
      .get<GetSsoClientResponseDto>(IdApiEndpointsEnum.GET_SSO_CLIENT, {
        clientUuid,
      });
  }

  getScopes(clientUuid: string): Observable<GetOAuthClientScopesResponseDto> {
    return this.#requestService
      .get<GetOAuthClientScopesResponseDto>(IdApiEndpointsEnum.GET_CLIENT_SCOPES, {
        clientUuid,
      });
  }

  getApis(clientUuid: string): Observable<GetClientApisResponseDto> {
    return this.#httpClient
      .get<GetClientApisResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.GET_CLIENT_APIS.path,
          {
            clientUuid,
          },
        ).toString(),
      );
  }

  getClientRedirectUris(clientUuid: string): Observable<GetClientRedirectUrisResponseDto> {
    return this.#httpClient
      .get<GetClientRedirectUrisResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.GET_CLIENT_REDIRECT_URIS.path,
          {
            clientUuid,
          },
        ).toString(),
      );
  }

  getOauthClientPostLogoutRedirectUris(
    clientUuid: string,
  ): Observable<GetOauthClientPostLogoutRedirectUrisResponseDto> {
    return this.#httpClient
      .get<GetOauthClientPostLogoutRedirectUrisResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.GET_OAUTH_CLIENT_POST_LOGOUT_REDIRECT_URIS.path,
          {
            clientUuid,
          },
        ).toString(),
      );
  }

  getOauthClientCorsUrls(clientUuid: string): Observable<GetOauthClientCorsUrlsResponseDto> {
    return this.#httpClient
      .get<GetOauthClientCorsUrlsResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.GET_OAUTH_CLIENT_CORS_URLS.path,
          {
            clientUuid,
          },
        ).toString(),
      );
  }

  getTestUsers(clientUuid: string): Observable<GetClientTestUsersResponseDto> {
    return this.#httpClient
      .get<GetClientTestUsersResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.GET_CLIENT_TEST_USERS.path,
          {
            clientUuid,
          },
        ).toString(),
      );
  }

  getDevelopers(clientUuid: string): Observable<GetClientDevelopersResponseDto> {
    return this.#httpClient
      .get<GetClientDevelopersResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.GET_CLIENT_DEVELOPERS.path,
          {
            clientUuid,
          },
        ).toString(),
      );
  }
}
