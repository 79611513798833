import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const IdApiEndpoints = {
  DELETE_API: {
    path: '/v1/apis/:apiUuid',
  },
  DELETE_API_REVOKE_URL: {
    path: '/v1/api-revoke-urls/:revokeUrlUuid',
  },
  DELETE_CLIENT_CORS_URL: {
    path: '/v1/client-cors-urls/:clientCorsUrlUuid',
  },
  DELETE_CLIENT_REDIRECT_URI: {
    path: '/v1/client-redirect-uris/:clientRedirectUriUuid',
  },
  DELETE_EMAIL_ADDRESS: {
    path: '/v1/emails/:uuid',
  },
  DELETE_PHONE_NUMBER: {
    path: '/v1/phones/:uuid',
  },
  DELETE_OAUTH_CLIENT_POST_LOGOUT_REDIRECT_URI: {
    path: '/v1/client-post-logout-redirect-uris/:clientPostLogoutRedirectUriUuid',
  },
  DELETE_REMOVE_API_DEVELOPER: {
    path: '/v1/apis/:apiUuid/developers/:developerUuid',
  },
  DELETE_REMOVE_CLIENT_API: {
    path: '/v1/apis/:apiUuid/clients/:clientUuid',
  },
  DELETE_REMOVE_OAUTH_CLIENT_ALLOWED_GRANT: {
    path: '/v1/oauth-clients/:clientUuid/allowed-grants/:grantUuid',
  },
  DELETE_REMOVE_OAUTH_CLIENT_API: {
    path: '/v1/oauth-clients/:clientUuid/apis/:apiUuid',
  },
  DELETE_REMOVE_OAUTH_CLIENT_DEVELOPER: {
    path: '/v1/oauth-clients/:clientUuid/developers/:developerUuid',
  },
  DELETE_REMOVE_OAUTH_CLIENT_TEST_USER: {
    path: '/v1/oauth-clients/:clientUuid/test-users/:testUserUuid',
  },
  DELETE_REMOVE_OAUTH_CLIENT_LOGIN_PROVIDER: {
    path: '/v1/oauth-clients/:clientUuid/login-providers/:loginProviderUuid',
  },
  DELETE_REMOVE_OAUTH_CLIENT_SCOPE: {
    path: '/v1/oauth-clients/:clientUuid/scopes/:scopeUuid',
  },
  DELETE_REMOVE_OAUTH_CLIENT_SCOPE_ALLOWED_GRANT: {
    path: '/v1/oauth-clients/:clientUuid/scopes/:scopeUuid/allowed-grants/:grantUuid',
  },
  DELETE_REMOVE_SSO_CLIENT_DEVELOPER: {
    path: '/v1/sso-clients/:clientUuid/developers/:developerUuid',
  },
  DELETE_REMOVE_SSO_CLIENT_TEST_USER: {
    path: '/v1/sso-clients/:clientUuid/test-users/:testUserUuid',
  },
  DELETE_SCOPE: {
    path: '/v1/scopes/:scopeUuid',
  },
  DELETE_SCOPE_EXPIRATION_DATE: {
    path: '/v1/scope-expiration-dates/:scopeExpirationDateUuid',
  },
  GET_API: {
    path: '/v1/apis/:apiUuid',
  },
  GET_AUTHORIZE: {
    path: '/v1/oauth/authorize',
  },
  GET_CLIENT_APIS: {
    path: '/v1/clients/:clientUuid/apis',
  },
  GET_CLIENTS: {
    path: '/v1/clients',
  },
  GET_CLIENT_DEVELOPERS: {
    path: '/v1/clients/:clientUuid/developers'
  },
  GET_CLIENT_REDIRECT_URIS: {
    path: '/v1/clients/:clientUuid/redirect-uris',
  },
  GET_CLIENT_SECRETS: {
    path: '/v1/client-secrets',
  },
  GET_CLIENT_TEST_USERS: {
    path: '/v1/clients/:clientUuid/test-users'
  },
  GET_COUNTRIES: {
    path: '/v1/countries/:lang',
  },
  GET_JWKS: {
    path: '/.well-known/jwks',
  },
  GET_OAUTH_CLIENT_CORS_URLS: {
    path: '/v2/oauth-clients/:clientUuid/cors-urls',
  },
  GET_OAUTH_CLIENT_INFO: {
    path: '/v1/oauth-clients/info/:clientUuid',
  },
  GET_OAUTH_CLIENT_POST_LOGOUT_REDIRECT_URIS: {
    path: '/v1/oauth-clients/:clientUuid/post-logout-redirect-uris'
  },
  GET_OAUTH_CLIENTS_USING_SCOPE: {
    path: '/v1/scopes/:scopeUuid/clients',
  },
  GET_OAUTH_GRANTS: {
    path: '/v1/oauth/oauth-grants',
  },
  GET_OPENID_CONFIGURATION: {
    path: '/.well-known/openid-configuration',
  },
  GET_PHONE_CODES: {
    path: '/v1/countries/:lang/phone-codes',
  },
  GET_POSTAL_CODE_LOOKUP: {
    path: '/v1/postal-codes/lookup/country/no/postal-code/:postalCode',
  },
  GET_SCOPES: {
    path: '/v1/scopes',
  },
  GET_SELF: {
    path: '/v2/users/self',
  },
  GET_SCOPE: {
    path: '/v1/scopes/:scopeUuid',
  },
  GET_SCOPE_EXISTS_BY_NAME: {
    path: '/v2/scopes/exists/by-name',
  },
  GET_SSO_CLIENT_INFO: {
    path: '/v1/sso-clients/info/:clientUuid',
  },
  GET_TOKEN: {
    path: '/v1/oauth/token',
  },
  GET_USERS: {
    path: '/v1/users',
  },
  GET_VALIDATE_EMAIL_ADDRESS: {
    path: '/v1/validate/email/:emailAddress',
  },
  GET_VALIDATE_PHONE_NUMBER: {
    path: '/v1/validate/phone/:phoneNumber',
  },
  GET_VERIFY_EMAIL_ADDRESS: {
    path: '/v1/emails/:uuid/verify/:code',
  },
  PATCH_SCOPE: {
    path: '/v1/scopes/:scopeUuid',
  },
  POST_ADD_API_DEVELOPER: {
    path: '/v1/apis/:apiUuid/developers/:developerUuid',
  },
  POST_ADD_CLIENT_ALLOWED_GRANT: {
    path: '/v1/oauth-clients/:clientUuid/allowed-grants/:grantUuid',
  },
  POST_ADD_CLIENT_API: {
    path: '/v1/apis/:apiUuid/clients/:clientUuid',
  },
  POST_ADD_OAUTH_CLIENT_DEVELOPER: {
    path: '/v1/oauth-clients/:clientUuid/developers/:developerUuid',
  },
  POST_ADD_OAUTH_CLIENT_LOGIN_PROVIDER: {
    path: '/v1/oauth-clients/:clientUuid/login-providers/:loginProviderUuid',
  },
  POST_ADD_OAUTH_CLIENT_SCOPE: {
    path: '/v1/oauth-clients/:clientUuid/scopes/:scopeUuid',
  },
  POST_ADD_OAUTH_CLIENT_SCOPE_ALLOWED_GRANT: {
    path: '/v1/oauth-clients/:clientUuid/scopes/:scopeUuid/allowed-grants/:grantUuid',
  },
  POST_ADD_OAUTH_CLIENT_TEST_USER: {
    path: '/v1/oauth-clients/:clientUuid/test-users/:testUserUuid',
  },
  POST_ADD_SSO_CLIENT_DEVELOPER: {
    path: '/v1/sso-clients/:clientUuid/developers/:developerUuid',
  },
  POST_ADD_SSO_CLIENT_TEST_USER: {
    path: '/v1/sso-clients/:clientUuid/test-users/:testUserUuid',
  },
  POST_API: {
    path: '/v1/apis',
  },
  POST_API_REVOKE_URL: {
    path: '/v1/api-revoke-urls',
  },
  POST_CLIENT_CORS_URL: {
    path: '/v1/client-cors-urls',
  },
  POST_CLIENT_REDIRECT_URI: {
    path: '/v1/client-redirect-uris',
  },
  POST_CLIENT_SECRET: {
    path: '/v1/client-secrets',
  },
  POST_EMAIL_ADDRESS: {
    path: '/v1/emails',
  },
  POST_OAUTH_CLIENT_POST_LOGOUT_REDIRECT_URI: {
    path: '/v1/client-post-logout-redirect-uris',
  },
  POST_PHONE_NUMBER: {
    path: '/v1/phones',
  },
  POST_REVOKE_CLIENT_SECRET: {
    path: '/v1/client-secrets/:uuid/revoke',
  },
  POST_SCOPE: {
    path: '/v1/scopes',
  },
  POST_SCOPE_EXPIRATION_DATE: {
    path: '/v1/scope-expiration-dates',
  },
  POST_SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL: {
    path: '/v1/emails/:uuid/send-verification-email',
  },
  POST_SEND_PHONE_NUMBER_VERIFICATION_SMS: {
    path: '/v1/phones/:uuid/send-verification-sms',
  },
  POST_SET_PRIMARY_EMAIL_ADDRESS: {
    path: '/v1/emails/:uuid/set-primary',
  },
  POST_SET_PRIMARY_PHONE_NUMBER: {
    path: '/v1/phones/:uuid/set-primary',
  },
  POST_POSTAL_ADDRESS: {
    path: '/v1/addresses',
  },
  POST_VERIFY_PHONE_NUMBER: {
    path: '/v1/phones/:uuid/verify/:code',
  },
  PUT_API: {
    path: '/v1/apis/:apiUuid',
  },
  PUT_CLIENT_REDIRECT_URI: {
    path: '/v1/client-redirect-uris/:clientRedirectUriUuid',
  },
  PUT_CONTACT_INFORMATION: {
    path: '/v1/contact-informations/:uuid',
  },
  PUT_OAUTH_CLIENT: {
    path: '/v1/oauth-clients/:uuid',
  },
  PUT_OAUTH_CLIENT_SCOPE: {
    path: '/v1/oauth-clients/:clientUuid/scopes/:scopeUuid',
  },
  PUT_OAUTH_CLIENT_POST_LOGOUT_REDIRECT_URI: {
    path: '/v1/client-post-logout-redirect-uris/:clientPostLogoutRedirectUriUuid',
  },
  PUT_SSO_CLIENT: {
    path: '/v1/sso-clients/:uuid',
  },
  PUT_POSTAL_ADDRESS: {
    path: '/v1/addresses/:uuid',
  },
  PUT_SELF: {
    path: '/v1/users/self',
  },
} satisfies FfNgxEndpointRecords;
