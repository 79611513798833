import {
  GetOAuthClientScopesResponseDto,
} from '@core/dtos/id-api/clients/oauth/scopes/get-o-auth-client-scopes-response.dto';
import { Scope } from '@core/models/oidc/scope';
import { GrantAbbreviations } from '@core/enums/grant-abbreviations';
import { GrantIcons } from '@core/enums/grant-icons';
import { ArrayHelper } from '@core/helpers/array.helper';

export function getOauthClientScopesResponseMapper(getClientScopesResponseDto: GetOAuthClientScopesResponseDto): Scope[] {
  if (null === getClientScopesResponseDto) {
    return [];
  }

  return getClientScopesResponseDto.scopes.map((s) => {
    const d: Scope = {
      uuid: s.uuid,
      name: s.name,
      description: s.description,
      locked: s.locked,
      required: s.required,
      global: s.global,
      allowedGrants: s.allowedGrants.map((ag) => {
        return {
          uuid: ag.uuid,
          name: ag.name,
          abbreviation: GrantAbbreviations[ag.name],
          icon: GrantIcons[ag.name],
        };
      }).sort(ArrayHelper.fieldSorter(['name'])),
      createdAt: new Date(s.createdAt),
    };

    if (s.expirationDate) {
      d.scopeExpirationDate = {
        uuid: s.expirationDate.uuid,
        createdAt: new Date(s.expirationDate.createdAt),
        deprecatedSince: new Date(s.expirationDate.deprecatedSince),
        notes: s.expirationDate.notes,
      };

      if (s.expirationDate.sunset) {
        d.scopeExpirationDate.sunset = new Date(s.expirationDate.sunset);
      }
    }

    return d;
  });
}
