@if (isAppAdmin()) {
  <div class="add-client-form-container">
    @if (showAddClientForm()) {
      @if (processingAddClientForm()) {
        <ff-ngx-loading-indicator class="add-client-loading-indicator" stroke="#2f2f2f" size="medium" />
      } @else {
        <button
          ffNgxButton
          (click)="showAddClientForm.set(false)"
          color="secondary"
        >Avbryt
        </button>
        <ff-ngx-form-field>
          <ff-ngx-input-container>
            <input
              type="text"
              ffNgxInput
              [ffNgxAutocomplete]="addClientAutocomplete"
              (input)="searchClients()"
              (focus)="searchClients()"
              placeholder="Søk..."
              #addClientSearchInput
            />
            <ff-ngx-icon
              name="30x30/picker-search"
              [size]="20"
              ffNgxInputContainerSuffix
            />
          </ff-ngx-input-container>
          <ff-ngx-form-field-error>
          </ff-ngx-form-field-error>
        </ff-ngx-form-field>
        <ff-ngx-autocomplete (optionSelected)="assignClient($event)" [displayWith]="displayClientWith"
                             #addClientAutocomplete="ffNgxAutocomplete">
          @for (searchedClient of searchedClients(); track searchedClient.uuid) {
            <ff-ngx-option [value]="searchedClient">
              <div class="client-list-component-clients-search-result-entry">
                <span class="name">{{ searchedClient.name }}</span>
                <span class="description">{{ searchedClient.description }}</span>
              </div>
            </ff-ngx-option>
          }
        </ff-ngx-autocomplete>
      }
    }
  </div>
}
<div class="clients">
  @for (client of sortedClients(); track client.uuid) {
    <a [routerLink]="['/clients', client.type, client.uuid]" class="client">
      @if (clientsBeingUnassigned().includes(client.uuid)) {
        <ff-ngx-loading-indicator class="un-assign-client-loading-indicator" stroke="#2f2f2f" size="s20" />
      } @else if (isAppAdmin()) {
        <ff-ngx-icon
          class="un-assign-client-icon"
          name="30x30/trash"
          [size]="20"
          (click)="unAssignClient(client, $event)"
        />
      }
      <app-client-list-client-entry [client]="client" />
    </a>
  } @empty {
    <p>Her er det ingen klienter.</p>
  }
</div>
