import { OAuthClient } from '@core/models/oidc/client/oauth-client';
import { SsoClient } from '@core/models/oidc/client/sso-client';
import { ClientType } from '@core/models/oidc/client/client-type';
import { GetClientsResponseDto } from '@core/dtos/id-api/clients/get-clients.response-dto';
import { GrantAbbreviations } from '@core/enums/grant-abbreviations';
import { GrantIcons } from '@core/enums/grant-icons';
import { ArrayHelper } from '@core/helpers/array.helper';

export function getClientsResponseMapper(getClientsResponseDto: GetClientsResponseDto): (OAuthClient | SsoClient)[] {
  return getClientsResponseDto.clients.map((c) => {
    switch (c.type) {
      case ClientType.OAUTH:
        return {
          uuid: c.uuid,
          type: c.type,
          accessTokenTtl: c.accessTokenTtl,
          active: c.active,
          allowedAcrValues: c.allowedAcrValues,
          allowedGrants: c.allowedGrants ? c.allowedGrants.map((ag) => {
            return {
              uuid: ag.uuid,
              name: ag.name,
              abbreviation: GrantAbbreviations[ag.name],
              icon: GrantIcons[ag.name],
            };
          }).sort(ArrayHelper.fieldSorter(['name'])) : [],
          bypassAuthorization: c.bypassAuthorization,
          canOmitScopes: c.canOmitScopes,
          contactInformation: c.contactInformation,
          createdAt: c.createdAt,
          lastUsedAt: c.lastUsedAt,
          description: c.description,
          devMode: c.devMode,
          idTokenTtl: c.idTokenTtl,
          loginProviders: c.loginProviders ? c.loginProviders.map((lp) => {
            return {
              uuid: lp.uuid,
              type: lp.type,
              name: lp.name,
              icon: lp.icon,
              active: lp.active,
              order: lp.order,
            };
          }) : [],
          name: c.name,
          public: c.public,
          refreshTokenTtl: c.refreshTokenTtl,
          requireEmailAddress: c.requireEmailAddress,
          requireFirstNameAndLastName: c.requireFirstNameAndLastName,
          requireInformationUpdate: c.requireInformationUpdate,
          requirePhoneNumber: c.requirePhoneNumber,
          requirePostalAddress: c.requirePostalAddress,
        };
      case ClientType.SSO:
        return {
          uuid: c.uuid,
          type: c.type,
          active: c.active,
          contactInformation: c.contactInformation,
          createdAt: c.createdAt,
          lastUsedAt: c.lastUsedAt,
          description: c.description,
          keyOption: c.keyOption,
          devMode: c.devMode,
          loginProviders: c.loginProviders ? c.loginProviders.map((lp) => {
            return {
              uuid: lp.uuid,
              type: lp.type,
              name: lp.name,
              icon: lp.icon,
              active: lp.active,
              order: lp.order,
            };
          }) : [],
          name: c.name,
          public: c.public,
          requireInformationUpdate: c.requireInformationUpdate,
        };
    }
  });
}
