import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';
import idApiConfig from '@config/apis/id/id-api.config';
import { PostAddClientApiResponseDto } from '@core/dtos/id-api/clients/oauth/apis/post-add-client-api.response-dto';
import {
  DeleteRemoveClientApiResponseDto,
} from '@core/dtos/id-api/clients/oauth/apis/delete-remove-client-api.response-dto';
import { GetApisResponseDto } from '@core/dtos/id-api/apis/get-apis.response-dto';
import { PostApiRequestDto } from '@core/dtos/id-api/apis/post-api.request-dto';
import { PostApiResponseDto } from '@core/dtos/id-api/apis/post-api.response-dto';
import { PutApiResponseDto } from '@core/dtos/id-api/apis/put-api.response-dto';
import { PutApiRequestDto } from '@core/dtos/id-api/apis/put-api.request-dto';
import { DeleteApiResponseDto } from '@core/dtos/id-api/apis/delete-api.response-dto';
import { GetApiResponseDto } from '@core/dtos/id-api/apis/get-api.response-dto';
import {
  DeleteRemoveApiDeveloperResponseDto,
} from '@core/dtos/id-api/apis/developers/delete-remove-api-developer.response-dto';
import {
  deleteRemoveApiDeveloperResponseMapper,
} from '@core/mappers/response/id-api/apis/delete-remove-api-developer.response-mapper';
import { PostAddApiDeveloperResponseDto } from '@core/dtos/id-api/apis/developers/post-add-api-developer.response-dto';
import {
  postAddApiDeveloperResponseMapper,
} from '@core/mappers/response/id-api/apis/post-add-api-developer.response-mapper';
import { Api } from '@core/models/oidc/api/api';

@Injectable({ providedIn: 'root' })
export class ApiService {
  readonly #httpClient = inject(HttpClient);

  assignClient(apiUuid: string, clientUuid: string): Observable<PostAddClientApiResponseDto> {
    return this.#httpClient
      .post<PostAddClientApiResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_ADD_CLIENT_API.path,
          {
            apiUuid,
            clientUuid,
          },
        ).toString(),
        {},
      );
  }

  unAssignClient(apiUuid: string, clientUuid: string): Observable<DeleteRemoveClientApiResponseDto> {
    return this.#httpClient
      .delete<DeleteRemoveClientApiResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.DELETE_REMOVE_CLIENT_API.path,
          {
            apiUuid,
            clientUuid,
          },
        ).toString(),
        {},
      );
  }

  deleteApi(apiUuid: string): Observable<DeleteApiResponseDto> {
    return this.#httpClient.delete<DeleteApiResponseDto>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.DELETE_API.path,
        {
          apiUuid,
        },
      ).toString(),
    );
  }

  getApi(apiUuid: string): Observable<GetApiResponseDto> {
    return this.#httpClient.get<GetApiResponseDto>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.GET_API.path,
        {
          apiUuid,
        },
      ).toString(),
    );
  }

  getApis(
    limit: number = 20,
    offset: number = 0,
    query?: string,
    onlyAccessible: boolean = true,
  ): Observable<GetApisResponseDto> {
    let params = new HttpParams();
    params = params.set('limit', limit);
    params = params.set('offset', offset);

    if (query) {
      params = params.set('query', query);
    }

    params = params.set('only-accessible', onlyAccessible.toString());

    return this.#httpClient
      .get<GetApisResponseDto>(EnvironmentHelper.fetchAPIBase('v1/apis'), {
        params,
      });
  }

  postApi(postApiRequestDto: PostApiRequestDto): Observable<PostApiResponseDto> {
    return this.#httpClient
      .post<PostApiResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_API.path,
        ).toString(), postApiRequestDto);
  }

  putApi(apiUuid: string, putApiRequestDto: PutApiRequestDto): Observable<PutApiResponseDto> {
    return this.#httpClient
      .put<PutApiResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.PUT_API.path,
          {
            apiUuid,
          },
        ).toString(), putApiRequestDto);
  }

  assignDeveloper(apiUuid: string, developerUuid: string): Observable<Api> {
    return this.#httpClient.post<PostAddApiDeveloperResponseDto>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.POST_ADD_API_DEVELOPER.path,
        {
          apiUuid,
          developerUuid,
        },
      ).toString(),
      {},
    ).pipe(
      map((r) => {
        return postAddApiDeveloperResponseMapper(r);
      }),
    );
  }

  unAssignDeveloper(apiUuid: string, developerUuid: string): Observable<Api> {
    return this.#httpClient
      .delete<DeleteRemoveApiDeveloperResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.DELETE_REMOVE_API_DEVELOPER.path,
          {
            apiUuid,
            developerUuid,
          },
        ).toString(),
        {},
      )
      .pipe(
        map((r) => {
          return deleteRemoveApiDeveloperResponseMapper(r);
        }),
      );
  }
}
