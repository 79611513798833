import {
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  input,
  model,
  OnInit,
  signal,
  viewChild,
  WritableSignal,
} from '@angular/core';
import { FfNgxAutocompleteOptionSelectedEvent, FfNgxModalService } from '@fagforbundet/ngx-components';
import { OAuthClient } from '@core/models/oidc/client/oauth-client';
import { SsoClient } from '@core/models/oidc/client/sso-client';
import { ApiService } from '@core/services/api/api.service';
import { User } from '@core/models/user/user';
import { RoleHelper } from '@core/helpers/role.helper';
import { Observable, of, Subject, switchMap } from 'rxjs';
import { Api } from '@core/models/oidc/api/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, finalize, map, tap } from 'rxjs/operators';
import { getClientsResponseMapper } from '@core/mappers/response/id-api/clients/get-clients.response-mapper';
import { ArrayHelper } from '@core/helpers/array.helper';
import { ClientService } from '@core/services/client/client.service';
import {
  getOauthClientScopesResponseMapper,
} from '@core/mappers/response/id-api/clients/oauth/get-oauth-client-scopes.response-mapper';
import {
  DeleteRemoveClientApiResponseDto,
} from '@core/dtos/id-api/clients/oauth/apis/delete-remove-client-api.response-dto';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})
export class ClientListComponent implements OnInit {
  readonly #apiService = inject(ApiService);
  readonly #clientService = inject(ClientService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #ffNgxModalService = inject(FfNgxModalService);

  api = input.required<Api>();
  self = input<User | undefined>();
  isAppAdmin = computed(() => {
    return this.self() && RoleHelper.isAppAdmin(this.self());
  });

  clients = model.required<(OAuthClient | SsoClient)[]>();
  sortedClients = computed(() => {
    return this.clients().sort(ArrayHelper.fieldSorter(['name']));
  });

  showAddClientForm = model<boolean>(false);
  addClientSearchInput = viewChild<ElementRef<HTMLInputElement>>('addClientSearchInput');
  processingAddClientForm: WritableSignal<boolean> = signal(false);
  clientsSearchSubject$ = new Subject<string>();
  searchedClients: WritableSignal<(OAuthClient | SsoClient)[]> = signal([]);
  clientsBeingUnassigned: WritableSignal<string[]> = signal([]);

  constructor() {
    effect(() => {
      const addClientSearchInput = this.addClientSearchInput();
      if (addClientSearchInput) {
        addClientSearchInput.nativeElement.focus();
      }
    });
  }

  ngOnInit(): void {
    this.clientsSearchSubject$.pipe(
      takeUntilDestroyed(this.#destroyRef),
      debounceTime(200),
      switchMap((filterValue) => {
        return this.#clientService.getClients(20, 0, filterValue, !this.isAppAdmin());
      }),
    ).subscribe((r) => {
      this.searchedClients.set(getClientsResponseMapper(r).filter((c) => {
        return this.clients().findIndex((existingClient) => {
          return existingClient.uuid === c.uuid;
        }) === -1;
      }));
    });
  }

  displayClientWith: (value: OAuthClient | SsoClient | null) => string = (
    value: OAuthClient | SsoClient | null,
  ) => {
    return value ? `${value.name}` : '';
  };

  searchClients(): void {
    let filterValue = this.addClientSearchInput()?.nativeElement.value.toLowerCase() || '';
    if ('' === filterValue) {
      return;
    }

    return this.clientsSearchSubject$.next(filterValue);
  }

  assignClient(event: FfNgxAutocompleteOptionSelectedEvent): void {
    this.processingAddClientForm.set(true);

    const client: OAuthClient | SsoClient = event.option.value();

    this.#apiService.assignClient(this.api().uuid, client.uuid).pipe(
      finalize(() => {
        this.processingAddClientForm.set(false);
      }),
    ).subscribe(() => {
      this.clients.update((c) => {
        return [
          ...c,
          client,
        ];
      });

      this.showAddClientForm.set(false);
      this.searchedClients.set([]);
    });
  }

  unAssignClient(toUnAssign: OAuthClient | SsoClient, $event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();

    let unAssignClientObs = this.#apiService.unAssignClient(this.api().uuid, toUnAssign.uuid).pipe(
      finalize(() => {
        this.clientsBeingUnassigned.update((a) => {
          return a.filter((uuid) => {
            return uuid !== toUnAssign.uuid;
          });
        });
      }),
    );

    let finalObs: Observable<DeleteRemoveClientApiResponseDto | null>;

    if (toUnAssign.type === 'oauth') {
      finalObs = this.#clientUsesScopesFromApi(toUnAssign, this.api())
        .pipe(
          tap((scopesInUse) => {
            if (scopesInUse) {
              this.#ffNgxModalService.openTextModal({
                title: 'Ulovlig handling!',
                bodyText: 'Man kan ikke fjerne en klient fra et API, så lenge den bruker scopes fra API-et.',
              });
            }
          }),
          switchMap((scopesInUse) => {
            if (scopesInUse) {
              return of(null);
            }

            return unAssignClientObs;
          }),
        );
    } else {
      finalObs = unAssignClientObs;
    }

    this.clientsBeingUnassigned.update((c) => {
      return [
        ...c,
        toUnAssign.uuid,
      ];
    });

    finalObs.subscribe((r) => {
      if (!r) {
        this.clientsBeingUnassigned.update((c) => {
          return c.filter((uuid) => {
            return uuid !== toUnAssign.uuid;
          });
        });
        return;
      }

      this.clients.update((c) => {
        return c.filter((existingClient) => {
          return existingClient.uuid !== toUnAssign.uuid;
        });
      });
    });
  }

  #clientUsesScopesFromApi(client: OAuthClient, api: Api): Observable<boolean> {
    return this.#clientService.getScopes(client.uuid).pipe(
      map((r) => {
        let found = false;

        getOauthClientScopesResponseMapper(r).map((scope) => {
          api.scopes.forEach((apiScope) => {
            if (scope.uuid === apiScope.uuid) {
              found = true;
            }
          });
        });

        return found;
      }),
    );
  }
}
